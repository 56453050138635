<template>
  <div class="container mt-5   px-md-5" style="margin-left:auto; margin-right:auto;">
    <div class=" mb-4">
        <h2 class="text-left" style="text-transform: capitalize;">{{title + nombre }}</h2>
    </div>
    <div class="container-fluid p-0">
      <button @click="showModal(1)" class="btn-primary"><i class="fa fa-plus mr-2"></i>Tarjeta</button>
    </div>
    <div class="my-4 mb-2">
      <!-- <h2 class="text-left">Mis solicitudes</h2> -->
    </div>

    <!-- tabla test -->
    <div class="bg-gray ">
      <b-table 
          hover
          responsive 
          :items="table_list" 
          :fields="fields" 
          :current-page="currentPage" 
          :per-page="perPage" 
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
      >
      <template #cell(name)="row">
          {{ row.value.first }} {{ row.value.last }}
      </template>
              <template #cell(fecha_inicio)="data">
                  <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_inicio).utc().format("DD-MM-YYYY") : '' }}</span>
              </template>
              <template #cell(fecha_fin)="data">
                  <span  >{{ data.item.fecha_fin != null ? $moment(data.item.fecha_fin).utc().format("DD-MM-YYYY") : '' }}</span>
              </template>
              <template #cell(action)="data">
                  <button @click="showModal(2, data.item)" class="btn-white-outline-small mx-1"><i class="fa fa-pen"></i></button>
                  <button @click="deleteItem(data.item)" class="btn-primary-small mx-1"><i class="fa fa-trash"></i></button>
              </template>
      <template #row-details="row">
          <b-card>
          <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
          </ul>
          </b-card>
      </template>
      </b-table>
        <div>
          
      </div>
      
    </div><b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>


    <!-- show details and edit -->    
    
  <b-modal ref="modal-item2" size="lg" hide-footer >
    <template #modal-title>
      <h3>{{modalText}}</h3>
    </template>

    <template>
      <form @submit.prevent="saveData()"  style="padding:10px">
          <div class="row mt-1">
            <div class="col-12 mb-2">
              <label for="">Titulo</label>
              <input required class="form-control" v-model="row.titulo" type="text" name="" id="">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 mb-2">
              <label for="">Descripción</label>
              <vue-editor class="form-control p-0 m-0" style="height:150px;" v-model="row.descripcion" :editorOptions="editorOptions"/>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 mb-2">
              <label for="">Texto botón</label>
              <input required class="form-control" v-model="row.texto_boton" type="text" name="" id="">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-12 mb-2">
              <label for="">Ruta botón</label><br>
                <input type="file" id="file2" ref="file2" v-on:change="onFileSelected('descargable',2)"/><br>
              <input required class="form-control" v-model="row.ruta_boton" type="text" name="" id="">
            </div>
          </div>

          <div class="row mt-1">
            <div class="col-12 mb-2">
              <label for="">Imagen</label><br>
              <input type="file" accept=".jpg" id="file1" ref="file1" v-on:change="onFileSelected('carousel_categorias',1)"/><br>
              <input class="form-control" v-model="row.urlimagen" type="text" name="" id="">
            </div>
          </div>


          <div class="row mt-4">
            <div class="col-12 mb-2">
              <b-button type="submit" class="btn-primary-small mx-1 float-right ml-3">Guardar</b-button>
              <b-button @click="$refs['modal-item2'].hide()" class="btn-white-outline-small float-right">Cancelar</b-button>
            </div>
          </div>
      </form>
    </template>
  </b-modal>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
// import FormVue from "@/components/Form.vue";
export default {
  components: {
    VueEditor
    // FormVue,
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      selected: null,
      editorOptions : {
        modules: {
          toolbar: false,
        },
      },
        categorias: [
          { id: 1, text: 'Categoría' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
        subcategorias: [
        ],
        Producto: [
          { id: 1, text: 'Producto' },
          { id: 2, text: 'This is First option' },
          { id: 3, text: 'Selected Option' },
          { id: 4, text: 'This is an option with object value' },
          { id: 5, text: 'This one is disabled'}
        ],
      perPage: 25,
      currentPage: 1,

      table: "categorias_carousel",
      modalText: 'Nueva Ficha',
      title: 'Carousel Categoría: ',
      items: [],

      table_list: [
        // { id: 1 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 2 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 3 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 4 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
        // { id: 5 , fecha: "10-09-2021", nombre: "Pablo Perez", producto: "EUR-962365", estado: "Pendiente" },
      ],
      row:{},
      categoria_id: 1,
      fields: [
          { key: 'id', label: 'id', sortable: true, sortDirection: 'desc', class: 'text-center' },
          { key: 'titulo', label: 'Titulo', sortable: true, class: 'text-center' },
          { key: 'descripcion', label: 'Descripción', sortable: true, class: 'text-center' },
          // { key: 'fecha_inicio', label: 'fecha inicio', sortable: true, class: 'text-center' },
          // { key: 'fecha_fin', label: 'fecha fin', sortable: true, class: 'text-center' },
          // { key: 'estado', label: 'Estado', sortable: true, class: 'text-center' },
          { key: 'action', label: 'Acciones',class: 'text-center'},

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      file1: null,
      filename1: null,
      file2: null,
      filename2: null,
      id:0,
      nombre: ''
    };
  },
  async mounted() {
    this.id = this.$route.params.id
    this.nombre = this.$route.params.categoria
    await this.getData();
    // await this.getSubcategories();
  },
  methods: {
    onFileSelected(type, item){
      if(item==1){
        this.file1 = event.target.files[0];
        let extension = this.file1.name.split('.')[1];
        this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename1 = type + '_' + this.filename1+ '.'+ extension;
      }
      if(item==2){
        this.file2 = event.target.files[0];
        let extension = this.file2.name.split('.')[1];
        this.filename2 = Math.floor(Math.random() * 9999999999999 + 1);
        this.filename2 = type + '_' + this.filename2 +'.'+ extension;
      }
     },  
    showModal(type, item){
      console.log(item)
      if(type == 2){
        this.modalText = 'Editar Ficha'
        this.row = item
      }
      else{
        this.row = {}
        this.modalText = 'Nueva Ficha'
      }
      this.$refs['modal-item2'].show()
    },
    async getData() {
      try {
        this.$isLoading(true)
        let res = await this.$store.dispatch("get", { path: this.table + '/getAllByCategory/'+ this.id});
        this.$isLoading(false)
        //console.log(res)
        if(res.length>0){
          this.table_list = res
          this.totalRows = this.table_list.length
        }
        else{
          this.table_list = []
          this.totalRows = this.table_list.length
        }
      } catch (error) {
        this.$isLoading(false)
        this.table_list = []
        this.totalRows = this.table_list.length
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error: " + error,
        });
      }
    },
    async saveData() {
      try {
        this.$isLoading(true)
        let req
        let uploadImg
        this.$isLoading(true)        
        if(this.filename1!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename1 ,file: this.file1})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.urlimagen = 'https://naotravelco.com/img/' + this.filename1
          }
        }
        if(this.filename2!= null){
          uploadImg = await this.$store.dispatch("onFileSelected", {filename:this.filename2 ,file: this.file2})
          if(uploadImg == true){
            console.log('----',uploadImg)
            this.row.ruta_boton = 'https://naotravelco.com/img/' + this.filename2
          }
        }
        //this.row.categoria_id = this.categoria_id
        //console.log(this.row)
        if(this.row.id){
          let path = this.table+ '/modify/'+ this.row.id
          //console.log('path', path)
          req = await this.$store.dispatch("put", {
            path: path,
            data: this.row
          });
        }
        else{
          this.row.categoria_id = this.id
          req = await this.$store.dispatch("post", {
            path: this.table + '/new',
            data: this.row
          });
        }
        this.$isLoading(false)
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Datos guardados correctamente!",
        });
        this.$refs['modal-item2'].hide()
        this.getData();
      } catch (error) {
        this.$isLoading(false)
        this.$swal({
          icon: "error",
          title: "Oopss!",
          text: "Error, revisar datos.",
        });        
        //alert(error);
      }
    },
    ///////////////
    gotoTanks(){
      console.log('voy a ...')
      this.$router.push('/admin/gracias_solicitud')
    },

    async deleteItem(item) {
      try {
        console.log(item.id)
        const sure = await confirm(
          "¿Está seguro que desea eliminar este elemento?"
        );
        if (!sure) {
          return;
        }
        const req = await this.$store.dispatch("delete", {
          path: this.table +'/' + item.id,
        });
        this.$swal({
          icon: "success",
          title: "Muy bien!",
          text: "Registro eliminado correctamente!",
        });        
        this.getData();
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Opps!",
          text: "Error: " + error,
        });        
        console.log(error)
        //alert(error);
      }
    },
  },
};
</script>

<style scoped>
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
 

</style>